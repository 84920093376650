.volume {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border-radius: 8px;
  height: 26px;
}

.volume::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #FFC967;
  width: 13px;
  height: 13px;
  border-radius: 1000px;
  position: relative;
}

.volume::-moz-range-thumb {
  width: 13px;
  height: 13px;
}