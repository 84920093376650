.slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border-radius: 8px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: #FFC967;
  width: 10px;
  height: 54px;
  border-radius: 5px;
  position: relative;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 42px;
}