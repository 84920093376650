@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');

html,
body {
  min-height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  background: linear-gradient(180deg, #EFF7FC 0%, #FFFFFF 100%);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Over-ride breaking style from Infinite-Scroll library  */
.infinite-scroll-component {
  overflow: initial !important;
}

.min-h-100svh {
  min-height: 100vh;
  min-height: 100svh;
}
